<template>
    <el-dialog
        :model-value="show"
        :before-close="onClose"
        :close-on-click-modal="false"
        custom-class="common-edit"
        title="新增巡更任务"
        width="888px"
    >
        <el-form ref="dataFormRef" :label-width="135" :model="dataForm" :rules="dataRules" label-position="left">
            <el-form-item label="用户名：" prop="name">
                <el-input v-model="dataForm.name" placeholder="请输入任务名"></el-input>
            </el-form-item>
            <el-form-item label="规则天数：" prop="task_day">
                <el-input v-model="dataForm.task_day" placeholder="请输入规则天数"></el-input>
            </el-form-item>
            <el-form-item label="规则次数：" prop="task_count">
                <el-input v-model="dataForm.task_count" placeholder="请输入规则次数"></el-input>
            </el-form-item>
            <el-form-item label="巡更单位：" prop="unit_id">
                <el-select v-model="dataForm.unit_id" filterable placeholder="请选择巡更单位">
                    <el-option
                        v-for="item in unitOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="结束后是否循环：" prop="is_cycle">
                <el-select v-model="dataForm.is_cycle" filterable placeholder="请选择结束后是否循环">
                    <el-option
                        v-for="item in isCycleOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发货单：" prop="orderId">
                <el-select v-model="dataForm.orderId" filterable placeholder="请选择发货单" @change="getDataList">
                    <el-option
                        v-for="item in orderOptions"
                        :key="item.id"
                        :label="item.order_sn"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="ID" prop="id" width="100" />
                <el-table-column label="设备号" prop="equipment" />
                <el-table-column label="生产序列号" prop="production" />
                <el-table-column label="资产序列号" prop="assets" />
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
        <template #footer>
            <el-button @click="onClose">取消</el-button>
            <el-button type="primary" @click="onSubmit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import { ElMessage } from "element-plus";
import { ADD_PATROL, GET_PATROL_POLE_DETAIL, GET_PATROL_POLE_ORDER, GET_PATROL_UNIT_LIST } from "@/api/patrol";
import useCommonList from "@/hooks/useCommonList";

let props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    id: { type: [String, Number] },
    detail: { type: Object }
});

let emit = defineEmits(["update:show", "update"]);

watch(
    () => ({ id: props.id, show: props.show }),
    (newVal) => {
        if (newVal.show) getOptions();
    },
    { deep: true }
);

// 获取数据
let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

let orderId = ref(null);

let getDataList = (val) => {
    if (val) orderId.value = val;
    GET_PATROL_POLE_DETAIL({
        id: orderId.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};

// 获取选项
let isCycleOptions = ref([
    { id: 0, name: "否" },
    { id: 1, name: "是" }
]);

let unitOptions = ref([]);

let orderOptions = ref([]);

let getOptions = () => {
    GET_PATROL_UNIT_LIST({}).then((res) => {
        unitOptions.value = res.data.data;
    });
    GET_PATROL_POLE_ORDER({ limit: 100000 }).then((res) => {
        console.log(res, 111);
        orderOptions.value = res.data.data.list;
    });
};

// 表单数据
let dataForm = ref({
    name: null,
    task_day: null,
    task_count: null,
    unit_id: null,
    is_cycle: null,
    orderId: null
});

let dataFormRef = ref();

let dataRules = {
    name: [{ required: true, message: "请输入任务名", trigger: "blur" }],
    task_day: [{ required: true, message: "请输入规则天数", trigger: "blur" }],
    task_count: [{ required: true, message: "请输入规则次数", trigger: "blur" }],
    unit_id: [{ required: true, message: "请选择巡更单位！", trigger: "change" }],
    is_cycle: [{ required: true, message: "请选择结束后是否循环！", trigger: "change" }],
    orderId: [{ required: true, message: "请选择发货单！", trigger: "change" }]
};

// 关闭
let onClose = () => {
    if (dataFormRef.value) dataFormRef.value.resetFields();
    emit("update");
    emit("update:show", false);
};

// 提交
let onSubmit = () => {
    dataFormRef.value.validate((valid) => {
        if (valid) {
            ADD_PATROL({
                name: dataForm.value.name,
                task_day: dataForm.value.task_day,
                task_count: dataForm.value.task_count,
                unit_id: dataForm.value.unit_id,
                is_cycle: dataForm.value.is_cycle,
                poles: selectionIds.value
            }).then((res) => {
                if (res.data.code === 200) {
                    onClose();
                    ElMessage.success("新增成功");
                }
            });
        }
    });
};
</script>

<style lang="scss" scoped>
.pagination-wrapper {
    margin-top: 20px;
}
</style>
