<template>
    <gk-searchbar
        v-model:form="searchForm"
        :config="searchConfig"
        :fold-display-number="3"
        :foldable="true"
        :options="searchOptions"
        @reset="getDataList"
        @search="getDataList"
    />
    <div class="common-list">
        <div class="button-wapper">
            <el-button type="primary" @click="openEditDialog()">新增任务</el-button>
            <el-button type="danger" @click="onDelete()">删除</el-button>
        </div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="ID" prop="id" width="100" />
                <el-table-column label="任务名称" prop="name" />
                <el-table-column label="规则天数" prop="task_day" />
                <el-table-column label="规则次数" prop="task_count" />
                <el-table-column label="是否循环">
                    <template #default="scope">{{ scope.row.is_cycle === 1 ? "是" : "否" }}</template>
                </el-table-column>
                <el-table-column label="状态">
                    <template #default="scope">
                        <template v-if="scope.row.status === 0">超时</template>
                        <template v-if="scope.row.status === 1">正常</template>
                        <template v-if="scope.row.status === 2">循环</template>
                    </template>
                </el-table-column>
                <el-table-column label="当前次数" prop="cumulative_number" />
                <el-table-column label="累计次数" prop="current_number" />
                <el-table-column label="操作" width="300">
                    <template #default="scope">
                        <el-button plain size="mini" type="primary" @click="openPoleDialog(scope.row.id)">
                            电杆列表
                        </el-button>
                        <el-button plain size="mini" type="primary" @click="openSignInDialog(scope.row.id)">
                            打卡记录
                        </el-button>
                        <el-button plain size="mini" type="primary" @click="openLogDialog(scope.row.id)">
                            循环记录
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </div>
    <patrol-edit v-model:show="editDialog.show" />
    <patrol-wire-pole :id="poleDialog.id" v-model:show="poleDialog.show" />
    <patrol-sign-in :id="signInDialog.id" v-model:show="signInDialog.show" />
    <patrol-log :id="logDialog.id" v-model:show="logDialog.show" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import useCommonList from "../../../hooks/useCommonList";
import { BATCH_DELETE_PATROL, GET_PATROL_LIST } from "@/api/patrol";
import PatrolWirePole from "./PatrolWirePole";
import PatrolSignIn from "./PatrolSignIn";
import PatrolLog from "./PatrolLog";
import PatrolEdit from "./PatrolEdit";
import { ElMessage, ElMessageBox } from "element-plus";

let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
});

// 搜索项
let searchConfig = ref([]);

let searchOptions = ref({});

let searchForm = ref({});

// 获取数据
let getDataList = () => {
    GET_PATROL_LIST({
        ...searchForm.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};

// 增删改
let editDialog = ref({
    show: false,
    id: null
});

let openEditDialog = (item = null) => {
    editDialog.value = {
        show: true,
        id: item ? item.id : null
    };
};

// 电杆列表
let poleDialog = ref({
    show: false,
    id: null
});

let openPoleDialog = (id) => {
    poleDialog.value = {
        show: true,
        id: id
    };
};

// 打卡列表
let signInDialog = ref({
    show: false,
    id: null
});

let openSignInDialog = (id) => {
    signInDialog.value = {
        show: true,
        id: id
    };
};

// 循环中列表
let logDialog = ref({
    show: false,
    id: null
});

let openLogDialog = (id) => {
    logDialog.value = {
        show: true,
        id: id
    };
};

let onDelete = (id = null) => {
    if (id) {
    } else {
        if (selectionIds.value.length < 1) {
            ElMessage.error("请先选择删除内容!");
            return false;
        }
        ElMessageBox.confirm("确认删除选中内容？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning"
        }).then(() => {
            BATCH_DELETE_PATROL({ ids: selectionIds.value }).then((res) => {
                if (res.data.code === 200) {
                    getDataList();
                    ElMessage.success("删除成功");
                }
            });
        });
    }
};
</script>
