<template>
    <el-dialog
        :model-value="show"
        :before-close="onClose"
        :close-on-click-modal="false"
        custom-class="common-edit"
        title="电杆列表"
        width="888px"
    >
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe>
                <el-table-column label="ID" prop="id" width="100" />
                <el-table-column label="设备编号" prop="asset.equipment" />
                <el-table-column label="序列号" prop="asset.assets_serial.name" />
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </el-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import { GET_PATROL_POLE_LIST } from "@/api/patrol";
import useCommonList from "@/hooks/useCommonList";

let props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    id: { type: [String, Number] }
});

let emit = defineEmits(["update:show"]);

watch(
    () => props.show,
    (newVal) => {
        if (newVal) {
            getDataList();
        } else {
            dataList.value = [];
        }
    },
    { deep: true }
);

// 获取列表
let { dataPagination, dataList } = useCommonList();

let getDataList = () => {
    GET_PATROL_POLE_LIST({
        id: props.id,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(111, res.data.data);
    });
};

// 关闭
let onClose = () => {
    emit("update:show", false);
};
</script>

<style lang="scss" scoped>
.pagination-wrapper {
    margin-top: 20px;
}
</style>
